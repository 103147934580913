.forside-container {
  padding: 0;
  max-width: 100%;
  background-color: #ffffff;
}

.development-banner {
  background-color: #000000;
  color: white;
  text-align: center;
  padding: 0.75rem;
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.hero-section {
  background-color: #fafafa;
  padding: 6rem 2rem;
  text-align: center;
  margin-bottom: 4rem;
}

.header-logo {
  max-width: 200px;
  margin-bottom: 3rem;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.header-logo:hover {
  transform: scale(1.03);
}

.content {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 2rem;
}

.intro-text {
  font-size: 1.4rem;
  line-height: 1.6;
  margin: 2rem auto;
  max-width: 700px;
  color: #333333;
  font-weight: 300;
}

.features {
  margin: 8rem 0;
  padding: 0 2rem;
}

.features h2 {
  text-align: center;
  margin-bottom: 4rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-item {
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(0,0,0,0.04);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.feature-item h3 {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #000000;
}

.feature-item p {
  font-size: 1rem;
  line-height: 1.6;
  color: #666666;
  margin: 0;
}

.demo-section {
  margin: 8rem 0;
  text-align: center;
}

.demo-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 4rem;
  margin-top: 3rem;
}

.demo-card {
  background: #ffffff;
  border-radius: 4px;
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.demo-card:hover {
  transform: translateY(-8px);
}

.demo-img {
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0 0;
  filter: brightness(1.02);
}

.demo-caption {
  padding: 1.5rem;
  margin: 0;
  font-weight: 400;
  color: #333333;
  font-size: 1.1rem;
}

h1 {
  color: #000000;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  font-weight: 500;
  letter-spacing: -0.5px;
}

h2 {
  color: #000000;
  font-size: 2.2rem;
  margin-bottom: 3rem;
  text-align: center;
  font-weight: 500;
  letter-spacing: -0.3px;
}

.contact-section {
  margin: 8rem 0 4rem 0;
  text-align: center;
  padding: 4rem 2rem;
  background-color: #fafafa;
  border-radius: 12px;
}

.contact-text {
  font-size: 1.2rem;
  color: #666666;
  margin-bottom: 2rem;
}

.contact-link {
  display: inline-block;
  color: #000000;
  font-size: 1.3rem;
  text-decoration: none;
  padding: 1rem 2rem;
  border: 2px solid #000000;
  border-radius: 6px;
  transition: all 0.3s ease;
}

.contact-link:hover {
  background-color: #000000;
  color: #ffffff;
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .hero-section {
    padding: 3rem 1rem;
  }
  
  .header-logo {
    max-width: 140px;
  }
  
  .intro-text {
    font-size: 1.1rem;
    padding: 0;
    margin: 1.5rem auto;
  }
  
  .features {
    margin: 3rem 0;
    padding: 0 1rem;
  }
  
  .features-grid {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .demo-images {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 0 1rem;
  }
  
  .demo-card {
    max-width: 100%;
  }
  
  .demo-caption {
    font-size: 1rem;
    padding: 1rem;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  h2 {
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }
  
  .contact-section {
    margin: 3rem 1rem;
    padding: 2rem 1rem;
  }
}

@media (max-width: 480px) {
  .feature-item {
    padding: 1.25rem;
  }
  
  .feature-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .feature-item h3 {
    font-size: 1.1rem;
  }
  
  .feature-item p {
    font-size: 0.9rem;
  }
  
  .contact-link {
    width: 100%;
    box-sizing: border-box;
  }
}
